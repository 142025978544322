import {observer} from 'mobx-react'
import * as React from 'react'
import {FC, useEffect} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import SectionTitle from '../../../Components/SectionTitle'
import {getRandomKey, getTranslation, handleFocus, removeHtmlTags} from '../../../Helpers/Default'
import VoteStore from '../../../Stores/Vote'
import {useHistory, useLocation} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import CardLayout from '../../../Components/CardLayout'
import {add, fraction} from 'mathjs'
import DefaultTableMui from '../../../Components/DefaultTableMui'
import TextEditor from '../../../Components/TextEditor'
import {InputLabel} from '../../../Components/InputLabel'
import {getVoteVisibilityOptions} from '../../../Helpers/Vote'
import UserStore from '../../../Stores/User'
import {DefaultButton} from '../../../Components/Buttons/DefaultButton'
import {hasCommitteeRole, hasCrudRole} from '../../../Helpers/User'
import {confirmDialog} from '../../../Helpers/ConfirmDialog'
import _, {cloneDeep} from 'lodash'
import {toJS} from 'mobx'
import SelectInline from '../../../Components/SelectInline'
import {showNotification} from '../../../Helpers/Notification'
import ValidationCodeModal from './ValidationCode'
import {DOCUMENTS_MODULE, VOTE_MODULE} from '../../../Constants/Modules'
import {TooltipDefault} from '../../../Components/Tooltip'
import SurveyStore from '../../../Stores/Survey'
import {DEFAULT_DATE_TIME_FORMAT} from '../../../Constants/Defatul'
import moment from 'moment'
import useCheckCrudRole from '../../../Hooks/CheckCrudRole'
import {IsDemo} from '../../../Helpers/Demo'

const VoteDetail: FC<{}> = observer((props: any) => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const [initialValues, setInitialValues] = React.useState<any>({
    votedUsers: [],
    keySelect: getRandomKey(),
  })
  const [activeKey, setActiveKey] = React.useState<any>([{}])
  const [allUsers, setAllUsers] = React.useState<any>([])
  const [voteProcess, setVoteProcess] = React.useState<any>(false)
  const [voted, setVoted] = React.useState<any>(false)
  const [voteFor, setVoteFor] = React.useState<any>(false)
  const [showVoteButton, setShowVoteButton] = React.useState<any>(false)
  const [data, setData] = React.useState<any>({})
  const [dataOrg, setDataOrg] = React.useState<any>({})
  const [pointsKey, setPointsKey] = React.useState<any>(getRandomKey())
  const [pageKey, setPageKey] = React.useState<any>(getRandomKey())
  const [showCodeModal, setShowCodeModal] = React.useState<any>(false)
  const [wasChange, setWasChange] = React.useState<any>(false)
  const [alreadyVote, setAlreadyVote] = React.useState<any>(true)
  const [isRunning, setIsRunning] = React.useState<any>(false)
  const [getVoteTickets, setGetVoteTickets] = React.useState<any>(false)
  const url_hash = props.match.params.id
  const [id, setId] = React.useState<any>(null)
  const defaultUserSelect = {
    value: 'all',
    label: getTranslation('VOTE.DETAIL.SELECT_USER.ALL', intl),
  }
  let returnBackParams = ''
  // @ts-ignore
  if (location?.state?.search) {
    // @ts-ignore
    returnBackParams = location.state.search
  }

  async function getData() {
    const voteData = await VoteStore.get(url_hash)
    if (voteData) {
      setId(voteData.id)
      const alreadyVoted = voteData.votePoints[0].votePointUsers.filter(
        (item: any) =>
          item.votedUser.userId === UserStore.user.id && item.votedUser.status === 'done'
      )

      const existInList = voteData.votePoints[0].votePointUsers.filter(
        (item: any) => item.votedUser.userId === UserStore.user.id
      )

      if (alreadyVoted.length > 0 || existInList.length === 0) {
        setVoted(true)
      }
      const existForVote = voteData.votePoints[0].votePointUsers.filter(
        (item: any) => item.votedUser.userId === UserStore.user.id
      )

      if (existForVote.length > 0) {
        setShowVoteButton(true)
        setAlreadyVote(false)
      }
      setIsRunning(voteData.status === 'run')
      setData(voteData)
      setDataOrg(cloneDeep(voteData))

      const mappedUsers = voteData.votePoints[0].votePointUsers
        .filter((user: any) => user.vote_value === null)
        .map((item: any) => ({
          value: item.votedUser.userId,
          label: item.votedUser.full_name,
        }))
      // mappedUsers.unshift({
      //   value: 'all',
      //   label: getTranslation('VOTE.DETAIL.SELECT_USER.ALL', intl),
      // })
      setAllUsers(mappedUsers)
    }

    if (UserStore.users.length === 0) {
      await UserStore.getAll()
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const changeValue = (e: any) => {
    setInitialValues((prevState: any) => {
      return {
        ...prevState,
        [e.name]: e.value,
      }
    })
  }

  const calculateTotalPercent = (fraction: any) => {
    return ((fraction.n / fraction.d) * 100).toFixed(2)
  }

  const getStatistic = (voteUsers: any) => {
    let totalCount = fraction('0')
    let statistic = {
      success: {
        title: 'Pro',
        count: 0,
        percent: fraction('0'),
        percentValue: 0,
      },
      danger: {
        title: 'Proti',
        count: 0,
        percent: fraction('0'),
        percentValue: 0,
      },
      warning: {
        title: 'Zdrželi se',
        count: 0,
        percent: fraction('0'),
        percentValue: 0,
      },
      info: {
        title: 'Nepřítomni',
        count: 0,
        percent: fraction('0'),
        percentValue: 0,
      },
    }
    let totalVotedCount = 0
    for (let i in voteUsers) {
      const voteUser = voteUsers[i]
      const share = JSON.parse(voteUser.votedUser.shares)
      const top = share.top || 1
      const bottom = share.bottom || 1
      const userFraction = fraction(`${top}/${bottom}`)
      totalCount = add(userFraction, totalCount)

      if (voteUser.vote_value === 'agree') {
        statistic.success.count += 1
        totalVotedCount += 1
        statistic.success.percent = add(userFraction, statistic.success.percent)
      } else if (voteUser.vote_value === 'notAgree') {
        statistic.danger.count += 1
        totalVotedCount += 1
        statistic.danger.percent = add(userFraction, statistic.danger.percent)
      } else if (voteUser.vote_value === 'noVote') {
        statistic.warning.count += 1
        totalVotedCount += 1
        statistic.warning.percent = add(userFraction, statistic.warning.percent)
      } else if (voteUser.vote_value === 'miss') {
        statistic.info.count += 1
        totalVotedCount += 1
        statistic.info.percent = add(userFraction, statistic.info.percent)
      }
    }

    for (let i in statistic) {
      // @ts-ignore
      const stat = statistic[i]
      stat.percentValue = Math.round(
        // @ts-ignore
        (calculateTotalPercent(stat.percent) / calculateTotalPercent(totalCount)) * 100
      )
    }

    return {
      statistic: statistic,
      totalCount: totalCount,
      totalVotedCount: totalVotedCount,
      totalUsers: voteUsers.length,
      totalVotedUsersPercent: Math.round((totalVotedCount / voteUsers.length) * 100),
      totalVotedPercent:
        statistic.success.percentValue +
        statistic.danger.percentValue +
        statistic.warning.percentValue,
    }
  }

  const renderStatistic = (voteUsers: any, pointIndex: any, isReadyPoint: boolean) => {
    const html = []
    let statistic = getStatistic(voteUsers)
    if (statistic.totalVotedCount > 0) {
      html.push(getAlertBox(isReadyPoint ? 'success' : 'danger'))
    }

    for (let i in statistic.statistic) {
      // @ts-ignore
      const stat = statistic.statistic[i]
      const percent = Math.round(
        // @ts-ignore
        (calculateTotalPercent(stat.percent) / calculateTotalPercent(statistic.totalCount)) * 100
      )
      html.push(
        <div
          className={`col-3 border ${
            i !== 'info' ? 'border-right-1' : 'border-right-0'
          } border-bottom-0 border-left-0 border-top-0`}
          key={i}
        >
          <div className='top'>
            <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
              <span className='fw-bold-custom fs-5'>{stat.title}</span>
              <span className={`fw-bold-customer fs-3 text-${i}`}>{stat.count}</span>
            </div>
          </div>
          <div className='bar'>
            <div className='h-5px w-100 bg-light mb-3'>
              <div
                className={`bg-${i} rounded h-5px`}
                role='progressbar'
                style={{width: percent + '%'}}
              ></div>
            </div>
            <div className='d-flex justify-content-end w-100 mt-auto mb-2 mt-3'>
              <span className='fw-bold-customer fs-6'>{percent}%</span>
            </div>
          </div>
        </div>
      )
    }
    return (
      <>
        <div className='statistic'>
          <div className='row'>{html}</div>
        </div>
        <div className='tableUsers w-100' key={'table_user_' + statistic.totalVotedCount}>
          {renderTableUser(voteUsers, statistic.totalCount, pointIndex)}
        </div>
      </>
    )
  }

  const changeVote = (pointIndex: any, row: any, type: string) => {
    let newType: any = type
    const foundIndex = data.votePoints[pointIndex].votePointUsers.findIndex(
      (item: any) => item.id === row.id
    )

    setData((prevState: any) => {
      const votePoints = prevState.votePoints
      if (votePoints[pointIndex].votePointUsers[foundIndex].vote_value === type) {
        newType = null
      }

      votePoints[pointIndex].votePointUsers[foundIndex].vote_value = newType
      votePoints[pointIndex].votePointUsers[foundIndex].changed = true
      return {
        ...prevState,
        votePoints: votePoints,
      }
    })
    setWasChange(true)
    setPointsKey(getRandomKey())
  }

  const renderTableUser = (voteUsers: any, totalCount: any, pointIndex: any) => {
    const columns = [
      {
        dataField: 'full_name',
        text: getTranslation('VOTE.DETAIL.TABLE.TITLE.FULL_NAME', intl),
        formatter: (cellContent: any, row: any) => {
          return row ? <div className={'d-inline-flex'}>{row.votedUser.full_name}</div> : '-'
        },
      },
      {
        dataField: 'rooms',
        text: getTranslation('VOTE.DETAIL.TABLE.TITLE.ROOMS', intl),
        formatter: (cellContent: any, row: any) => {
          // @ts-ignore
          return row.votedUser.room_name || '-'
        },
      },
      {
        dataField: 'spare',
        text: getTranslation('VOTE.DETAIL.TABLE.TITLE.SPARE', intl),
        formatter: (cellContent: any, row: any) => {
          // @ts-ignore
          return row.votedUser.spare ? row.votedUser.spare : '-'
        },
      },
      {
        dataField: 'voted_date',
        text: getTranslation('VOTE.DETAIL.TABLE.TITLE.VOTED', intl),
        formatter: (cellContent: any, row: any) => {
          // @ts-ignore
          return row.votedUser.status === 'done'
            ? moment(row.votedUser.updatedAt).format(DEFAULT_DATE_TIME_FORMAT)
            : '-'
        },
      },
      {
        dataField: 'share',
        text: getTranslation('VOTE.DETAIL.TABLE.TITLE.SHARE', intl),
        formatter: (cellContent: any, row: any) => {
          const share = JSON.parse(row.votedUser.shares)
          const top = share.top || 1
          const bottom = share.bottom || 1
          const userFraction = fraction(`${top}/${bottom}`)
          const percent = Math.round(
            // @ts-ignore
            (calculateTotalPercent(userFraction) / calculateTotalPercent(totalCount)) * 100
          )
          return percent + '%'
        },
      },
      {
        dataField: 'action',
        text: getTranslation('VOTE.DETAIL.TABLE.ACTION', intl),
        // style: {
        //   width: '350px',
        // },
        labelClass: 'text-right',
        classes: ' text-right',
        headerClasses: ' text-right',
        formatter: (cellContent: any, row: any) => {
          const conditionForVote =
            (!voted && voteProcess) ||
            (voteProcess && voteFor) ||
            hasCrudRole(VOTE_MODULE) ||
            (voteFor && data.type !== 'online') ||
            (voteFor && data.status === 'run' && hasCrudRole(VOTE_MODULE)) ||
            (data.type === 'local' && data.status === 'run' && hasCrudRole(VOTE_MODULE))

          return (
            <div className={'d-inline-flex justify-content-center align-items-center'}>
              <div
                className={`s d-none d-print-block px-2 py-1  ${
                  row.vote_value === 'agree' ? 'vote-success' : ''
                } me-2`}
              >
                {getTranslation('VOTE.DETAIL.TABLE.ACTION.AGREE', intl)}
              </div>
              <div
                className={`s d-none d-print-block px-2 py-1  ${
                  row.vote_value === 'notAgree' ? 'vote-danger' : ''
                } me-2`}
              >
                {getTranslation('VOTE.DETAIL.TABLE.ACTION.NOT_AGREE', intl)}
              </div>
              <div
                className={`s d-none d-print-block px-2 py-1  ${
                  row.vote_value === 'noVote' ? 'vote-warning' : ''
                } me-2`}
              >
                {getTranslation('VOTE.DETAIL.TABLE.ACTION.NO_VOTE', intl)}
              </div>
              <div
                className={`s d-none d-print-block px-2 py-1  ${
                  row.vote_value === 'miss' ? 'vote-primary' : ''
                } me-2`}
              >
                {getTranslation('VOTE.DETAIL.TABLE.ACTION.MISS', intl)}
              </div>
              <div
                className={`btn btn-sm btn-bg-light ${
                  row.vote_value === 'agree' ? 'btn-success' : ''
                } me-2
                ${
                  ((!row.vote_value && (voteProcess || voteFor)) || data.type !== 'online') &&
                  isRunning
                    ? 'btn-active-success'
                    : 'cursor-default'
                }
                `}
                onClick={() => {
                  if (conditionForVote) {
                    //|| data.type !== 'online'
                    changeVote(pointIndex, row, 'agree')
                  }
                }}
              >
                {getTranslation('VOTE.DETAIL.TABLE.ACTION.AGREE', intl)}
              </div>
              <div
                className={`btn btn-sm btn-bg-light ${
                  row.vote_value === 'notAgree' ? 'btn-danger' : ''
                } me-2
                ${
                  ((!row.vote_value && (voteProcess || voteFor)) || data.type !== 'online') &&
                  isRunning
                    ? 'btn-active-danger'
                    : 'cursor-default'
                }
                `}
                onClick={() => {
                  if (conditionForVote) {
                    //|| data.type !== 'online'
                    changeVote(pointIndex, row, 'notAgree')
                  }
                }}
              >
                {getTranslation('VOTE.DETAIL.TABLE.ACTION.NOT_AGREE', intl)}
              </div>
              <div
                className={`btn btn-sm btn-bg-light ${
                  row.vote_value === 'noVote' ? 'btn-warning' : ''
                } me-2
                ${
                  ((!row.vote_value && (voteProcess || voteFor)) || data.type !== 'online') &&
                  isRunning
                    ? 'btn-active-warning'
                    : 'cursor-default'
                }
                `}
                onClick={() => {
                  if (conditionForVote) {
                    //|| data.type !== 'online'
                    changeVote(pointIndex, row, 'noVote')
                  }
                }}
              >
                {getTranslation('VOTE.DETAIL.TABLE.ACTION.NO_VOTE', intl)}
              </div>
              <div
                className={`btn btn-sm btn-bg-light ${
                  row.vote_value === 'miss' ? 'btn-primary' : ''
                } me-2
                ${
                  ((!row.vote_value && (voteProcess || voteFor)) || data.type !== 'online') &&
                  isRunning
                    ? 'btn-active-primary'
                    : 'cursor-default'
                }
                `}
                onClick={() => {
                  if (conditionForVote) {
                    //|| data.type !== 'online'
                    changeVote(pointIndex, row, 'miss')
                  }
                }}
              >
                {getTranslation('VOTE.DETAIL.TABLE.ACTION.MISS', intl)}
              </div>
            </div>
          )
        },
      },
    ]

    let voteUsersData = voteUsers
    if (voteProcess) {
      voteUsersData = voteUsersData.filter(
        (item: any) => item.votedUser.userId === UserStore.user.id
      )
    } else if (voteFor && hasCommitteeRole()) {
      voteUsersData = _.filter(voteUsersData, (item) => {
        return _.some(initialValues.votedUsers, {value: item.votedUser.userId})
      })
    }

    const alreadyVoted = voteUsersData.filter((item: any) => {
      return (
        (item.votedUser.status === 'done' && data.type === 'online') ||
        (data.type === 'local' && item.vote_value !== null)
      )
    })

    voteUsersData = voteUsersData.filter(
      (item: any) =>
        (item.votedUser.status !== 'done' && data.type === 'online') ||
        (data.type === 'local' && item.vote_value === null)
    )

    return (
      <>
        {voteUsersData.length > 0 ? (
          <div key={pointsKey + '_at_' + voteUsersData.length}>
            <SectionTitle>{getTranslation('VOTE.DETAIL.TITLE.PEOPLE', intl)}</SectionTitle>
            <DefaultTableMui
              data={voteUsersData}
              columns={columns}
              showColumnFilters={false}
              pagination={false}
            />
          </div>
        ) : null}
        {voteProcess || alreadyVoted.length === 0 ? null : (
          <div className='mt-8' key={pointsKey + '_2at_' + alreadyVoted.length}>
            <SectionTitle>{getTranslation('VOTE.DETAIL.TITLE.PEOPLE.VOTED', intl)}</SectionTitle>
            <DefaultTableMui
              data={alreadyVoted}
              columns={columns}
              showColumnFilters={false}
              pagination={false}
            />
          </div>
        )}
      </>
    )
  }

  const getAlertBox = (type: string) => {
    return (
      <div className={`alert alert-${type} d-flex align-items-center p-5 mb-7 border-left-10`}>
        <div className='d-flex flex-column'>
          {/*<h4 className='mb-1 text-dark'>This is an alert</h4>*/}
          <div className={'fw-bold-custom '}>
            {getTranslation(
              type === 'danger' ? 'VOTE.DETAIL.ALERT.DANGER' : 'VOTE.DETAIL.ALERT.SUCCESS',
              intl
            )}
          </div>
        </div>
      </div>
    )
  }

  const deleteVoteDialog = () => {
    confirmDialog('', getTranslation('VOTE.DETAIL.CONFIRM.DELETE_VOTE', intl), async () => {
      if (await VoteStore.remove(id)) {
        history.push(`/hlasovani${returnBackParams}`)
      }
    })
  }
  const renderPoints = () => {
    const html = []
    let sortPoints = []
    if (data.votePoints) {
      sortPoints = data.votePoints.sort((a: any, b: any) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        // @ts-ignore
        return dateA - dateB
      })
    }
    for (let i in sortPoints) {
      const point = sortPoints[i]
      const isReadyPoint = checkIfVoteConditionAreReady(point.votePointUsers)
      html.push(
        <div className={'mt-5'} key={i} id={`vote_point_${i}`}>
          <div className='accordion-item bg-white'>
            <div
              className='accordion-header ps-6 py-6 cursor-pointer'
              onClick={() => {
                if (activeKey.indexOf(i) > -1) {
                  setActiveKey(activeKey.filter((item: any) => item !== i))
                } else {
                  setActiveKey([...activeKey, i])
                }
              }}
            >
              <div className='row'>
                <div className='col-12 align-items-center cursor-pointer'>
                  <h2
                    className={
                      'fw-bold-custom text-dark mb-0 d-inline-flex align-items-center justify-content-center'
                    }
                  >
                    <TooltipDefault
                      tooltip={getTranslation(
                        isReadyPoint ? 'VOTE.DETAIL.ALERT.SUCCESS' : 'VOTE.DETAIL.ALERT.DANGER',
                        intl
                      )}
                      position={'top'}
                    >
                      <span>
                        {isReadyPoint ? (
                          <i className='bi bi-check text-success fs-175 fw-bold-custom me-3 pt-2'></i>
                        ) : (
                          <i className='las la-times text-danger fs-125 fw-bold-custom me-3'></i>
                        )}
                      </span>
                    </TooltipDefault>
                    {point.name}
                  </h2>
                </div>
              </div>
            </div>
            <div
              className={`accordion-collapse collapse ${activeKey.indexOf(i) > -1 ? 'show' : ''}`}
            >
              <div className='accordion-body pt-0'>
                <div className='description border-bottom border-top mb-5 py-5'>
                  <div className='row align-items-baseline'>
                    <InputLabel
                      colSize={'col-2'}
                      required={false}
                      inlineLabel={true}
                      alignTop={false}
                    >
                      {getTranslation('VOTE.FORM.VOTE_POINT.DESCRIPTION', intl)}
                    </InputLabel>

                    <div className='col-9'>
                      {point.description ? (
                        <div
                          className={'w-semibold text-gray-800 fs-6'}
                          dangerouslySetInnerHTML={{__html: point.description}}
                        ></div>
                      ) : null}
                    </div>
                    <div className='col-1'>
                      <span
                        className='btn btn-link btn-color-primary btn-active-color-info'
                        onClick={deleteVoteDialog}
                      ></span>
                    </div>
                  </div>
                </div>

                {renderStatistic(point.votePointUsers, i, isReadyPoint)}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return <div key={pointsKey}>{html}</div>
  }

  const closeCodeModal = () => {
    setTimeout(() => {
      let element = document.getElementById('kt_body')
      if (element && element.style.overflow) {
        element.style.removeProperty('overflow')
      }
    }, 100)
    setShowCodeModal(false)
  }

  const validateAllPoints = (userId: string) => {
    let isValidate = true
    // if (voteProcess) {
    const votePoints = data.votePoints
    for (let i in votePoints) {
      const point = votePoints[i]
      let votePointUsers = point.votePointUsers
      votePointUsers = votePointUsers.filter((item: any) => item.votedUser.userId === userId)
      for (let j in votePointUsers) {
        const votePointUser = votePointUsers[j]
        if (!votePointUser.vote_value) {
          isValidate = false
        }
      }
    }
    // }

    return isValidate
  }

  const storeVoteWithCode = async (code: any) => {
    const enteredCode = code.join('')
    let points: any = []
    const users = data.status === 'online' ? initialValues.votedUsers : toJS(UserStore.users)
    for (let votedUserIndex in users) {
      const votedUser = users[votedUserIndex]
      for (let i in data.votePoints) {
        const point = data.votePoints[i]
        let votePointUsers = point.votePointUsers
        votePointUsers = votePointUsers.filter(
          (item: any) => item.votedUser.userId === votedUser.id
        )
        for (let j in votePointUsers) {
          const votePointUser = votePointUsers[j]

          points.push({
            vote_value: votePointUser.vote_value,
            vote_point_id: point.id,
            voted_user_id: votePointUser.votedUser.id,
          })
        }
      }
    }
    const ids = data.votePoints[0].votePointUsers
      .filter((item: any) => item.changed)
      .map((item: any) => item.votedUser.userId)
      .join(',')

    if (await VoteStore.storeWithCode(id, points, enteredCode, ids)) {
      getData()
      setWasChange(false)
      if (voteFor && voted) {
        setShowVoteButton(false)
      }
      setShowCodeModal(false)
      setTimeout(() => {
        let element = document.getElementById('kt_body')
        if (element && element.style.overflow) {
          element.style.removeProperty('overflow')
        }
      }, 100)
      setVoteFor(false)
      changeVoteUser({target: {value: [], name: 'votedUsers'}})
      setVoteProcess(false)
      setPointsKey(getRandomKey())
      setPageKey(getRandomKey())
    }
  }

  const saveVotes = async () => {
    // const validPoints = validateAllPoints(UserStore.user.id)
    let nextStep = true
    if (voteFor) {
      for (let i in initialValues.votedUsers) {
        const validPoints = validateAllPoints(initialValues.votedUsers[i].value)
        if (!validPoints) {
          showNotification('warning', getTranslation('VOTE.DETAIL.ERRORS.VOTE_NOT_COMPLETE', intl))
          nextStep = false
          return false
        }
      }
    } else {
      const validPoints = validateAllPoints(UserStore.user.id)
      if (!validPoints) {
        showNotification('warning', getTranslation('VOTE.DETAIL.ERRORS.VOTE_NOT_COMPLETE', intl))
        nextStep = false
        return false
      }
    }
    // const validPoints = validateAllPoints(initialValues.votedUsers[0])
    // if (!validPoints) {
    //   showNotification('error', getTranslation('VOTE.DETAIL.ERRORS.VOTE_NOT_COMPLETE', intl))
    //   return false
    // }

    if ((nextStep && initialValues.votedUsers.length > 0 && voteFor) || (nextStep && !voteFor)) {
      if (IsDemo()) {
        return false
      }
      setShowCodeModal(true)
      sendCodeEmail(id)
    }
  }

  const downloadTickets = async () => {
    const selectedUsers = initialValues.votedUsers
    let data = []
    if (selectedUsers.length === 0) {
      data = []
    } else {
      for (let i in selectedUsers) {
        data.push(selectedUsers[i].value)
      }
    }
    if (await VoteStore.voteTickets(id, data)) {
      setGetVoteTickets(false)
    }
  }

  const checkIfVoteConditionAreReady = (votePointUsers: any[]) => {
    const statistic = getStatistic(votePointUsers)

    if (statistic.totalVotedUsersPercent < data.participation_count) {
      return false
    }
    if (statistic.statistic.success.percentValue <= data.accept_count) {
      //|| vote.users.length !== 0
      return false
    }

    return true

    // const totalVotedPercent = statistic.totalVotedPercent
    // const successPercentValue = statistic.statistic.success.percentValue
    // const totalPercentValues =
    //   statistic.statistic.danger.percentValue +
    //   statistic.statistic.success.percentValue +
    //   statistic.statistic.warning.percentValue
    // const successPercent = (successPercentValue / totalPercentValues) * 100
    //
    // return totalVotedPercent >= data.accept_count && successPercent >= data.participation_count
  }

  const sendCodeEmail = async (voteId: string) => {
    const ids = data.votePoints[0].votePointUsers
      .filter((item: any) => item.changed)
      .map((item: any) => item.votedUser.userId)
      .join(',')
    await VoteStore.sendCode(voteId, ids)
  }

  const createKeys = () => {
    const arrayKeys = []
    for (let i = 0; i < 100; i++) {
      arrayKeys.push(i.toString())
    }

    return arrayKeys
  }

  const changeVoteUser = (e: any) => {
    let oldData = {...initialValues}
    if (e.target.name === 'votedUsers') {
      const currentSelection = e.target.value

      const isIncomingAll = currentSelection.some((item: any) => item.value === 'all')

      if (isIncomingAll && currentSelection[0].value !== 'all') {
        oldData[e.target.name] = []
      } else if (currentSelection.length > 1 && currentSelection[0].value === 'all') {
        oldData[e.target.name] = currentSelection.filter((item: any) => item.value !== 'all')
      } else {
        if (currentSelection.length === 0) {
          oldData[e.target.name] = []
        } else {
          oldData[e.target.name] = currentSelection
        }
      }
    } else {
      oldData[e.target.name] = e.target.value
    }

    oldData['keySelect'] = getRandomKey()

    setInitialValues(oldData)
  }

  const pageButtons = []

  if (
    hasCrudRole(VOTE_MODULE) &&
    !voteFor &&
    !voteProcess &&
    data.type === 'online' &&
    data.status === 'run'
  ) {
    pageButtons.push(
      <DefaultButton
        onClick={(e: any) => {
          e.preventDefault()
          // setVoteProcess(true)
          setVoteFor(true)
          setShowVoteButton(true)
        }}
        className={'btn btn-success btn-sm'}
        id={'vote_start_vote_for'}
        loading={false}
        disable={false}
      >
        {getTranslation('VOTE.DETAIL.BUTTONS.VOTE_FOR', intl)}
      </DefaultButton>
    )

    pageButtons.push(
      <DefaultButton
        onClick={(e: any) => {
          e.preventDefault()
          setGetVoteTickets(true)
        }}
        className={'btn btn-primary btn-sm ms-3 pe-3 ps-4'}
        id={'vote_ticket'}
        loading={false}
        disable={false}
        tooltip={getTranslation('VOTE.DETAIL.TOOLTIP.VOTE_TICKET', intl)}
        icon={<i className='fas fa-clipboard-list'></i>}
      ></DefaultButton>
    )
  }
  if (data.status === 'finish' && hasCrudRole(VOTE_MODULE)) {
    pageButtons.push(
      <DefaultButton
        onClick={(e: any) => {
          e.preventDefault()
          VoteStore.setValue('isPrinting', true)
          setActiveKey(createKeys())
          setTimeout(() => {
            window.print()

            VoteStore.setValue('isPrinting', false)
          }, 2000)
        }}
        className={'btn btn-primary btn-sm ms-3'}
        id={'vote_print'}
        loading={VoteStore.isPrinting}
        disable={VoteStore.isPrinting}
        tooltip={getTranslation('BASE.BUTTONS.PRINT', intl)}
        icon={<i className='bi bi-printer pe-0'></i>}
      ></DefaultButton>
    )
  }

  const finishVote = async () => {
    if (await VoteStore.finish(id)) {
      history.push(`/hlasovani${returnBackParams}`)
    }
  }

  const renderFooterButtons = () => {
    const rightButtons = []
    const leftButtons = [
      <DefaultButton
        onClick={(e: any) => {
          e.preventDefault()
          if (wasChange) {
            confirmDialog('', getTranslation('VOTE.DETAIL.CONFIRM.GO_BACK_CHANGES', intl), () => {
              history.push(`/hlasovani${returnBackParams}`)
            })
          } else {
            history.push(`/hlasovani${returnBackParams}`)
          }
        }}
        className={'btn btn-danger btn-sm'}
        id={'vote_back_vote'}
        loading={false}
        disable={false}
      >
        {getTranslation('VOTE.DETAIL.BUTTONS.BACK_VOTE', intl)}
      </DefaultButton>,
    ]
    if (hasCrudRole(VOTE_MODULE)) {
      leftButtons.push(
        <DefaultButton
          onClick={(e: any) => {
            e.preventDefault()
            confirmDialog('', getTranslation('VOTE.DETAIL.CONFIRM.DELETE_VOTE', intl), async () => {
              if (await VoteStore.remove(id)) {
                history.push(`/hlasovani${returnBackParams}`)
              }
            })
          }}
          className={'btn btn-light-danger btn-sm ms-3'}
          id={'vote_delete_vote'}
          loading={false}
          disable={false}
        >
          {getTranslation('VOTE.DETAIL.BUTTONS.VOTE_DELETE', intl)}
        </DefaultButton>
      )
    }

    if (isRunning && showVoteButton) {
      if (data.type === 'online') {
        if (!voted || (hasCrudRole(VOTE_MODULE) && (voteProcess || voteFor))) {
          rightButtons.push(
            <>
              {voteProcess || voteFor ? (
                <>
                  <DefaultButton
                    onClick={(e: any) => {
                      e.preventDefault()
                      setData(cloneDeep(dataOrg))
                      if (voteFor && !voted) {
                        setShowVoteButton(true)
                      } else {
                        setShowVoteButton(true)
                      }
                      setVoteFor(false)
                      changeVoteUser({target: {value: [], name: 'votedUsers'}})
                      setVoteProcess(false)
                      setPointsKey(getRandomKey())
                    }}
                    className={'btn btn-danger btn-sm me-3'}
                    id={'vote_cancel_vote'}
                    loading={false}
                    disable={false}
                  >
                    {getTranslation('VOTE.DETAIL.BUTTONS.CANCEL_VOTE', intl)}
                  </DefaultButton>

                  <DefaultButton
                    onClick={(e: any) => {
                      e.preventDefault()
                      confirmDialog('', getTranslation('VOTE.DETAIL.CONFIRM.VOTE', intl), saveVotes)
                    }}
                    className={'btn btn-success btn-sm me-3'}
                    id={'vote_start_vote'}
                    loading={false}
                    disable={false}
                  >
                    {getTranslation('VOTE.DETAIL.BUTTONS.SAVE_VOTES', intl)}
                  </DefaultButton>
                </>
              ) : (
                <DefaultButton
                  onClick={(e: any) => {
                    e.preventDefault()
                    setVoteProcess(true)
                    setPointsKey(getRandomKey())
                    setActiveKey(data.votePoints.map((item: any, index: any) => index.toString()))
                    handleFocus('[id="vote_point_0"]', 'id')
                  }}
                  className={'btn btn-success btn-sm me-3'}
                  id={'vote_start_vote'}
                  loading={false}
                  disable={false}
                >
                  {getTranslation('VOTE.DETAIL.BUTTONS.START_VOTE', intl)}
                </DefaultButton>
              )}
            </>
          )
        }
      } else {
      }
    }

    if (hasCrudRole(VOTE_MODULE)) {
      rightButtons.push(
        <DefaultButton
          onClick={(e: any) => {
            e.preventDefault()
            confirmDialog('', getTranslation('VOTE.DETAIL.CONFIRM.FINISH', intl), finishVote)
          }}
          className={'btn btn-primary btn-sm me-3'}
          id={'vote_end_vote'}
          loading={false}
          disable={false}
        >
          {getTranslation('VOTE.DETAIL.BUTTONS.FINISH', intl)}
        </DefaultButton>
      )

      if (wasChange && data.type !== 'online') {
        rightButtons.push(
          <DefaultButton
            onClick={(e: any) => {
              e.preventDefault()
              confirmDialog('', getTranslation('VOTE.DETAIL.CONFIRM.SAVE_CHANGES', intl), () => {
                storeVoteWithCode([''])
              })
            }}
            className={'btn btn-success btn-sm'}
            id={'vote_save_changes_vote_2'}
            loading={false}
            disable={false}
          >
            {getTranslation('VOTE.DETAIL.BUTTONS.SAVE_CHANGES', intl)}
          </DefaultButton>
        )
      }
    }
    return (
      <>
        <div className='left'>{leftButtons}</div>
        <div className='right'>{isRunning ? rightButtons : null}</div>
      </>
    )
  }

  if (getVoteTickets) {
    return (
      <>
        <PageTitle linkBack={`/hlasovani${returnBackParams}`} pageButtons={pageButtons}>
          {getTranslation('VOTE.TITLE.DETAIL', intl)}
        </PageTitle>
        <CardLayout key={initialValues.keySelect}>
          <SectionTitle>{getTranslation('VOTE.TITLE.VOTE_TICKETS', intl)}</SectionTitle>
          <div className='row'>
            <div className='col-12'>
              <SelectInline
                values={initialValues}
                name={'votedUsers'}
                // placeholder={getTranslation('ROOMS.FORM.PLACEHOLDER', intl)}
                label={getTranslation('VOTE.DETAIL.VOTE_FROM_PEOPLE.SELECT', intl)}
                formik={{errors: {}, touched: {}}}
                multipleSelect={true}
                required={false}
                changeValue={changeVoteUser}
                readOnly={false}
                data={allUsers}
                // readOnlyFormat={(value: any) => {
                //   return getFullName(value)
                // }}
                option={{
                  value: 'value',
                  name: function (value: any) {
                    return value.label
                  },
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 d-inline-flex w-100 justify-content-between align-items-center'>
              <DefaultButton
                onClick={(e: any) => {
                  setGetVoteTickets(false)
                }}
                className={'btn btn-danger btn-sm me-3'}
                id={'cancel_vote_ticket'}
                loading={false}
                disable={false}
              >
                {getTranslation('VOTE.DETAIL.BUTTONS.BACK_VOTE', intl)}
              </DefaultButton>
              <DefaultButton
                onClick={downloadTickets}
                className={'btn btn-success btn-sm'}
                id={'getTickets'}
                loading={VoteStore.gettingTickets}
                disable={VoteStore.gettingTickets}
              >
                {getTranslation('VOTE.DETAIL.BUTTONS.GET_TICKETS', intl)}
              </DefaultButton>
            </div>
          </div>
        </CardLayout>
      </>
    )
  }

  return (
    <>
      <PageTitle linkBack={`/hlasovani${returnBackParams}`} pageButtons={pageButtons}>
        {getTranslation('VOTE.TITLE.DETAIL', intl)}
      </PageTitle>

      {voteFor ? (
        <CardLayout key={initialValues.keySelect}>
          <SectionTitle>{getTranslation('VOTE.DETAIL.TITLE.VOTE_FROM_PEOPLE', intl)}</SectionTitle>
          <div className='row'>
            <div className='col-12'>
              <SelectInline
                values={initialValues}
                name={'votedUsers'}
                // placeholder={getTranslation('ROOMS.FORM.PLACEHOLDER', intl)}
                label={getTranslation('VOTE.DETAIL.VOTE_FROM_PEOPLE.SELECT', intl)}
                formik={{errors: {}, touched: {}}}
                multipleSelect={true}
                required={true}
                changeValue={changeVoteUser}
                readOnly={false}
                data={allUsers.filter((user: any) => user.value !== 'all')}
                // readOnlyFormat={(value: any) => {
                //   return getFullName(value)
                // }}
                option={{
                  value: 'value',
                  name: function (value: any) {
                    return value.label
                  },
                }}
              />
            </div>
          </div>
        </CardLayout>
      ) : (
        <CardLayout>
          <SectionTitle>{getTranslation('VOTE.DETAIL.TITLE.SETTINGS', intl)}</SectionTitle>
          <div key={pageKey}>
            <div className={'row align-items-center'}>
              <InputLabel colSize={'col-3'} required={false} inlineLabel={true} alignTop={false}>
                {getTranslation('VOTE.FORM.NAME', intl)}
              </InputLabel>

              <div className='col-9 w-semibold text-gray-800 fs-6'>
                <strong>{data.name}</strong>
              </div>
            </div>
            {data.description && removeHtmlTags(data.description) && data.description !== '' ? (
              <div className={'row align-items-top'}>
                <InputLabel colSize={'col-3'} required={false} inlineLabel={true} alignTop={false}>
                  Doplňující informace
                </InputLabel>

                <div className='col-9 w-semibold text-gray-800 fs-6'>
                  <TextEditor
                    name={'description'}
                    isEdit={false}
                    key={getRandomKey()}
                    value={data.description}
                    action={changeValue}
                    formik={{errors: {}, touched: {}}}
                  />
                </div>
              </div>
            ) : null}
            <div className={'row align-items-center'}>
              <InputLabel colSize={'col-3'} required={false} inlineLabel={true} alignTop={false}>
                {getTranslation('VOTE.FORM.VISIBILITY', intl)}
              </InputLabel>

              <div className='col-9 w-semibold text-gray-800 fs-6'>
                {data.isPublic
                  ? getVoteVisibilityOptions(intl)[0].label
                  : getVoteVisibilityOptions(intl)[1].label}
              </div>
            </div>
            <div className={'row align-items-center'}>
              <InputLabel colSize={'col-3'} required={false} inlineLabel={true} alignTop={false}>
                {getTranslation('VOTE.FORM.SELECT_MEETING', intl)}
              </InputLabel>

              <div className='col-9 w-semibold text-gray-800 fs-6'>
                {data.meetingOfOwners
                  ? data.meetingOfOwners.name
                  : getTranslation('VOTE.FORM.OWNER_MEETING.NO_SELECT', intl)}
              </div>
            </div>
            <div className={'row align-items-center'}>
              <InputLabel colSize={'col-3'} required={false} inlineLabel={true} alignTop={false}>
                {getTranslation('VOTE.FORM.SELECT_VOTE_TYPE', intl)}
              </InputLabel>

              <div className='col-9 w-semibold text-gray-800 fs-6'>
                <strong>
                  {data.type === 'online'
                    ? getTranslation('VOTE.TYPE.ONLINE', intl)
                    : getTranslation('VOTE.TYPE.LOCAL', intl)}
                </strong>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-12 border-top mt-3 pt-5 w-semibold text-gray-800 fs-6'>
                <div>
                  <FormattedMessage
                    id='VOTE.DETAIL.PARTICIPATION'
                    values={{
                      strong: (chunks: any) => <strong>{chunks}</strong>, // Umožní použít <strong> tag pro {value}
                      values: data.participation_count,
                    }}
                  />
                </div>
                <div>
                  <FormattedMessage
                    id='VOTE.DETAIL.ACCEPT_COUNT'
                    values={{
                      strong: (chunks: any) => <strong>{chunks}</strong>, // Umožní použít <strong> tag pro {value}
                      values: data.accept_count,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardLayout>
      )}

      {renderPoints()}

      <div key={'buttons_' + pageKey} className={'w-100 border-top pt-8'}>
        <div className='d-inline-flex justify-content-between align-content-center w-100 page-buttons-area'>
          {renderFooterButtons()}
        </div>
      </div>

      {showCodeModal ? (
        <ValidationCodeModal
          key={pageKey + '_code'}
          show={showCodeModal}
          onHide={null}
          voteId={id}
          votedUsers={initialValues.votedUsers}
          sendAgain={sendCodeEmail}
          close={closeCodeModal}
          confirm={storeVoteWithCode}
        />
      ) : null}
    </>
  )
})

export default VoteDetail
