/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
// import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
// import * as auth from '../redux/AuthRedux'
// import {login} from '../redux/AuthCRUD'
import {useIntl} from 'react-intl'
import {getTranslation} from '../../../Helpers/Default'
import {observer} from 'mobx-react'
import AuthStore from '../../../Stores/Auth'
import {setLocalStorageValue} from '../../../Helpers/LocalStorage'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const initialValues = {
  email: 'demopristup@svjaplikace.cz',
  password: 'demoPristup19',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const Login = observer(() => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(getTranslation('LOGIN.FORM.MESSAGES.EMAIL.WRONG_FORMAT', intl))
      .required(getTranslation('LOGIN.FORM.MESSAGES.EMAIL.REQUIRE', intl)),
    password: Yup.string().required(getTranslation('LOGIN.FORM.MESSAGES.PASSWORD.REQUIRE', intl)),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setLocalStorageValue('svj', null)
      if (await AuthStore.login(values)) {
      } else {
      }
      setLoading(false)
      // setTimeout(() => {
      //   login(values.email, values.password)
      //     .then(({data: {api_token}}) => {
      //       setLoading(false)
      //       dispatch(auth.actions.login(api_token))
      //     })
      //     .catch(() => {
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })
  return (
    <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
      {/* begin::Logo */}
      <a href='#' className='mb-12'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
      </a>
      <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>{getTranslation('LOGIN.TITLE', intl)}</h1>
          </div>
          {/* begin::Heading */}

          {
            formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : null
            // <div className='mb-10 bg-light-info p-8 rounded'>
            //   <div className='text-info'>
            //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            //     continue.
            //   </div>
            // </div>
          }

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bold-customer text-dark'>
              {getTranslation('LOGIN.EMAIL', intl)}
            </label>
            <input
              placeholder={getTranslation('LOGIN.EMAIL.PLACEHOLDER', intl)}
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              value={'demopristup@svjaplikace.cz'}
              disabled={true}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container invalid-feedback'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bold-customer text-dark fs-6 mb-0'>
                  {getTranslation('LOGIN.PASSWORD', intl)}
                </label>
                {/* end::Label */}
              </div>
            </div>
            <input
              type='password'
              disabled={true}
              autoComplete='off'
              placeholder={getTranslation('LOGIN.PASSWORD.PLACEHOLDER', intl)}
              {...formik.getFieldProps('password')}
              value={'demoPristup19'}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container invalid-feedback'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting}
            >
              {!loading && (
                <span className='indicator-label'>{getTranslation('LOGIN.BUTTON', intl)}</span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {getTranslation('LOGIN.LOADING', intl)}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            {/* begin::Link */}
            {/* end::Link */}
          </div>
          <div className='w-100 text-center' style={{textAlign: 'center'}}>
            {/*<Link*/}
            {/*  to='/zapomenute-heslo'*/}
            {/*  className='link-primary fs-6 fw-bold-customer'*/}
            {/*  // style={{marginLeft: '5px'}}*/}
            {/*>*/}
            {/*  {getTranslation('LOGIN.PASSWORD.FORGOT', intl)}*/}
            {/*</Link>*/}
          </div>
          {/* end::Action */}
        </form>
      </div>
    </div>
  )
})

export default Login
